import { Component } from '@angular/core';

@Component({
  selector: 'rc-ui-divider',
  standalone: true,
  styles: [':host { width: 100%}'],
  template: `<div
    class="border-primary w-full border-top-1 opacity-50 my-3"
  ></div>`,
})
export class RcUiDividerComponent {}
